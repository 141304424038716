.frontendOuter {
  height: 100%;
  overflow: hidden auto;
}

.header {
  &.darkHeader {
    & .headerFluid {
      background-color: white;
      transform: translateY(0) !important;
      box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
        rgba(0, 0, 0, 0.24) 0px 1px 2px;

      &::after {
        display: none !important;
      }
    }

    & .navAnchor {
      color: black !important;
    }

    & .logo {
      & img {
        filter: invert(1);
      }
    }

    & .navWrapper {
      & .navlinks {
        & .navBtn {
          &:hover {
            background-color: #517991;
            opacity: 0.7 !important;
          }
        }
      }
    }
  }

  &.scrolled {
    & .headerFluid {
      transform: translateY(0px);

      @include sm {
        transform: translateY(-48px);
      }

      background-color: #517991;

      @include sm {
        transform: translateY(-48px);
      }

      &::after {
        display: none;
      }

      .menuColorClass {
        & svg {
          color: white !important;
        }
      }
    }

    & .navWrapper {
      & .navlinks {
        & .navBtn {
          background-color: #fff;
          color: #517991;
          border-color: #fff;

          &:hover {
            background-color: #fff;
            opacity: 0.9 !important;
          }
        }
      }
    }

    & .navAnchor {
      color: white !important;
    }

    & .logo {
      & img {
        filter: invert(0);
      }
    }
  }
}

.headerFluid {
  transition: 0.3s linear;
  position: fixed;
  top: 0px;
  z-index: 999;

  // &::after {
  //     @include sm {
  //         content: '';
  //         position: absolute;
  //         top: 48px;
  //         left: 0;
  //         width: 100%;
  //         height: 1px;
  //         background-color: #ffffff42;
  //     }
  // }

  & .headerDivider {
    padding-block: 12px;
  }

  & .navWrapper {
    width: 100%;
    padding: 15px;

    @include sm {
    }

    @include md {
    }

    @include lg {
      padding-inline: 15px;
    }

    & .logo {
      height: 40px;
      width: 110px;

      @include sm {
        width: auto;
      }

      @include md {
      }

      @include lg {
        height: 55px;
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    & .navlinks {
      & .menuBar {
        width: 20px;
        height: 20px;

        & svg {
          width: 100%;
          height: 100%;
          color: white;
        }
      }

      & .clickLi {
        position: relative;

        &.active {
          & .dropDownWrapper {
            display: flex;
          }
        }
      }

      & .navLi {
        margin-right: 25px;

        &:last-child {
          margin-right: 0;
        }

        & .navAnchor {
          text-decoration: none;
          font-size: 17px;
          color: white;
          font-weight: 500;
          transition: 0.3s linear;

          &:hover {
            opacity: 0.6;
          }

          &.navAnchorTop {
            font-size: 13px !important;
          }
        }
      }

      & .navBtn {
        border: 1px solid #34617d;
        border-radius: 50px;
        color: white;
        background-color: #517991;
        transition: 0.3s linear;
        font-size: 14px;
        padding: 8px 15px;

        & svg {
          width: 16px;
          height: 16px;
          margin-left: 3px;
        }

        @include sm {
        }

        @include md {
        }

        @include lg {
          font-size: 14px;
          padding: 0.5rem 1rem 0.5rem 1rem;
        }

        &:hover {
          opacity: 0.8;
          background-color: transparent;
        }
      }

      & .dropDownWrapper {
        position: absolute;
        top: 50px;
        right: 0;
        display: none;
        background-color: #0000007c !important;
        // background-color: #91a0a838 !important;
        border: 1px solid #517991;
        border-radius: 4px;
        padding: 15px;
        width: 305px;

        & .dropUl {
          & .dropLi {
            list-style: none;
            margin-bottom: 10px;

            // background-color: #34617D;
            &:last-child {
              margin-bottom: 0;
            }

            & .dropAnchor {
              text-decoration: none;
              font-size: 14px;
              font-weight: 500;
              color: white;
              padding: 10px;
              width: 100%;
              display: block;
              border-radius: 4px;
              transition: 0.3s linear;

              &:hover {
                background-color: #34617d;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

.offcanvas-header {
  & .offcanvas-title {
    & .logo {
      height: 40px;

      & img {
        height: 100%;
      }

      @include sm {
        height: 55px;
      }
    }
  }
}

.heroFluid {
  position: sticky;
  top: 0;

  & .sliderFluid {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(black, 0.5);
      width: 100%;
      height: 100%;
      z-index: -5;
    }

    & .paddingAuto {
      padding-top: 0 !important;

      @include sm {
        padding-top: 150px !important;
      }
    }

    & .heroTxtWrapper {
      color: white;
      z-index: 5;
      text-align: start;
      padding-top: 70px;

      @include sm {
        padding-top: 150px;
      }

      @include md {
      }

      @include lg {
        text-align: start;
        // width: 600px;
      }

      & .heading {
        font-weight: 600;
        line-height: 1.2;
        font-size: 28px;

        @include sm {
        }

        @include md {
        }

        @include lg {
          font-size: 45px;
        }
      }

      & .numberHeading {
        font-weight: 500;
        line-height: 1.2;
        font-size: 28px;

        @include sm {
        }

        @include md {
        }

        @include lg {
          font-size: 65px;
        }

        @include xxl {
          font-size: 90px;
        }
      }

      & .numberSubheading {
        font-weight: 600;
        line-height: 1.2;
        font-size: 20px;

        @include sm {
        }

        @include md {
        }

        @include lg {
          font-size: 30px;
        }
      }

      & .para {
        font-size: 15px;
        font-weight: 400;
        line-height: 1.5;
        margin-bottom: 15px;
        margin-top: 15px;

        @include sm {
        }

        @include md {
        }

        @include lg {
          font-size: 18px;
          margin-bottom: 30px;
          margin-top: 25px;
        }

        & .numitalic {
          font-size: 22px;
          line-height: 1.3;

          @include sm {
            font-size: 25px;
          }

          @include md {
            font-size: 32px;
          }

          @include lg {
            font-size: 38px;
          }
        }

        & .authorNametxt {
          font-size: 18px;
          line-height: 1.3;
          margin-left: 25px;

          @include sm {
            font-size: 22px;
          }

          @include md {
            font-size: 25px;
          }

          @include lg {
            font-size: 30px;
          }
        }
      }

      & .nuroBtn {
        background-color: #34617d;
        border-radius: 50px;
        border: 1px solid #34617d;
        font-size: 12px;
        color: white;
        font-weight: 600;
        padding: 8px 16px;
        transition: 0.3s linear;

        @include sm {
        }

        @include md {
        }

        @include lg {
          padding: 12px 25px;
          font-size: 14px;
        }

        &:hover {
          background-color: transparent;
        }

        & svg {
          height: 25px;
          width: 25px;
          margin-left: 3px;
        }
      }
    }
  }

  & .slider {
    width: 100%;
    height: 100vh;
    z-index: -5;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-attachment: fixed;
    }
  }
}

.sectionOneFluid {
  z-index: 5;
}

.offcanvas {
  background-color: #eae6e1;
  width: 300px !important;

  & .offcanvas-body {
    & .navlinks {
      & .menuBar {
        width: 20px;
        height: 20px;

        & svg {
          width: 100%;
          height: 100%;
          color: white;
        }
      }

      & .clickLi {
        position: relative;

        &.active {
          & .dropDownWrapper {
            display: flex;
          }
        }
      }

      & .navLi {
        margin-right: 25px;

        &:last-child {
          margin-right: 0;
        }

        & .navAnchor {
          text-decoration: none;
          font-size: 17px;
          color: white;
          font-weight: 500;
          transition: 0.3s linear;

          &:hover {
            opacity: 0.6;
          }

          &.navAnchorTop {
            font-size: 13px !important;
          }
        }
      }

      & .navBtn {
        border: 1px solid #34617d;
        border-radius: 50px;
        color: white;
        background-color: #517991;
        transition: 0.3s linear;
        font-size: 14px;
        padding: 8px 15px;

        & svg {
          width: 16px;
          height: 16px;
          margin-left: 3px;
        }

        @include sm {
        }

        @include md {
        }

        @include lg {
          font-size: 14px;
          padding: 0.5rem 1rem 0.5rem 1rem;
        }

        &:hover {
          opacity: 0.8;
        }
      }

      & .dropDownWrapper {
        position: absolute;
        top: 50px;
        right: 0;
        display: none;
        background-color: #91a0a838;
        border: 1px solid #517991;
        border-radius: 4px;
        padding: 15px;
        width: 305px;

        & .dropUl {
          & .dropLi {
            list-style: none;
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }

            & .dropAnchor {
              text-decoration: none;
              font-size: 14px;
              font-weight: 500;
              color: white;
              padding: 10px;
              width: 100%;
              display: block;
              border-radius: 4px;
              transition: 0.3s linear;

              &:hover {
                background-color: #34617d;
              }
            }
          }
        }
      }
    }
  }
}

.footerFluid {
  background-color: #f4f4f4 !important;
  width: 100%;
  position: relative;
  padding-bottom: 100px;

  @include sm {
    padding-bottom: 0;
  }

  & .logo {
    // height: 40px;
    // & img{
    //     height: 100%;
    //     width: 100%;
    // }
    // @include sm{
    //     height: 55px;
    // }
  }

  & .copyRyt {
    font-size: 16px;
    color: black;
  }

  & .navAnchor {
    color: #34617d !important;
    border-bottom: 1px solid;
    padding-bottom: 2px;
  }

  & .para {
    font-size: 16px;
    color: black;
  }

  & .address {
    font-size: 16px;
    margin-top: 10px;
    font-weight: 500;
  }

  & .navlinks {
    & .menuBar {
      width: 20px;
      height: 20px;

      & svg {
        width: 100%;
        height: 100%;
        color: white;
      }
    }

    & .clickLi {
      position: relative;

      &.active {
        & .dropDownWrapper {
          display: flex;
        }
      }
    }

    & .navLi {
      margin-right: 25px;

      &:last-child {
        margin-right: 0;
      }

      & .navAnchor {
        text-decoration: none;
        font-size: 17px;
        color: white;
        font-weight: 500;
        transition: 0.3s linear;

        &:hover {
          opacity: 0.6;
        }

        &.navAnchorTop {
          font-size: 13px !important;
        }
      }
    }

    & .navBtn {
      border: 1px solid #34617d;
      border-radius: 50px;
      color: white;
      background-color: #517991;
      transition: 0.3s linear;
      font-size: 14px;
      padding: 8px 15px;
      flex-wrap: nowrap;

      & svg {
        width: 16px;
        height: 16px;
        margin-left: 3px;
      }

      @include sm {
      }

      @include md {
      }

      @include lg {
        font-size: 14px;
        padding: 0.5rem 1rem 0.5rem 1rem;
      }

      &:hover {
        opacity: 0.8;
      }
    }

    & .dropDownWrapper {
      position: absolute;
      top: 50px;
      right: 0;
      display: none;
      background-color: #91a0a838;
      border: 1px solid #517991;
      border-radius: 4px;
      padding: 15px;
      width: 305px;

      & .dropUl {
        & .dropLi {
          list-style: none;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          & .dropAnchor {
            text-decoration: none;
            font-size: 14px;
            font-weight: 500;
            color: white;
            padding: 10px;
            width: 100%;
            display: block;
            border-radius: 4px;
            transition: 0.3s linear;

            &:hover {
              background-color: #34617d;
            }
          }
        }
      }
    }
  }
}

.blogTestiImg {
  height: 250px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  border: 2px solid rgba(81, 121, 145, 0.231) !important;

  @include sm {
    height: 300px;
  }

  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.imgSectionblog {
  height: 350px;
}

.authorRow {
  -webkit-backdrop-filter: blur(10px) !important;
  backdrop-filter: blur(10px) !important;
  bottom: 20px !important;
  left: 20px !important;
  padding: 15px !important;
  position: absolute !important;
  right: 20px !important;
  z-index: 5 !important;
}

.authorList {
  font-size: 16px;
  font-weight: 600;
  color: white;
  font-style: italic;
  z-index: 2525;
}

.blogHeading {
  font-size: 30px;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #23506d;
  }
}

.CardRead {
  font-size: 22px;
}

.cardReadAnc {
  color: #23506d;
}

.cardNew {
  & .ImageCard {
    height: 360px;
    display: flex;
    align-items: center;

    & img {
      width: 100%;
      height: 100%;
    }
  }

  & .contantBox {
    background-color: #517991;
    padding: 12px;
    border-radius: 15px;
    margin: -140px 20px 0 20px;

    height: auto;
    & td {
      vertical-align: top;
    }
    @include sm {
      padding: 20px;
    }
    @include lg {
      margin: -170px 40px 0 40px;
      height: 400px !important;
    }
    @include xl {
      margin: -170px 40px 0 40px;
      height: 380px !important;
    }

    & .heading {
      font-weight: 600;
      line-height: 1.2;
      font-size: 25px;
      color: white;

      @include sm {
      }

      @include md {
      }

      @include lg {
      }
    }

    & .subHeading {
      font-size: 12px;
      color: rgb(238, 237, 237);
      font-weight: 200;
      line-height: normal;
      @include sm {
        font-size: 14px;
      }

      @include xl {
        font-size: 16px;
      }
      & span {
        font-size: 14px;
        font-weight: 500;
        padding-right: 2px;
        color: white;
        @include sm {
          font-size: 16px;
        }
        @include xl {
          font-size: 17px;
        }
      }
    }
  }
}

.imageFluid {
  & .imageBox {
    @include sm {
    }

    @include md {
    }

    @include lg {
      display: flex;
      gap: 5px;
    }

    & .imageInnerBox {
      width: 100%;
      height: auto;
      margin-bottom: 20px;

      @include sm {
      }

      @include md {
      }

      @include lg {
        width: 20%;
      }

      transition: 0.3s ease-in-out;

      &:hover {
        width: 100%;

        & .imageWrapper {
          & .imageTextWrapper {
            backdrop-filter: blur(20px);
            // background-color: #34617D;
            transform: translateY(0);
          }
        }

        & .image {
          & img {
            transform: scale(1.5);
          }
        }
      }

      // &.active{
      //     width: 100%;
      //     & .imageWrapper{
      //         & .imageTextWrapper{
      //             display: block;
      //         }
      //     }
      // }
      & .imageWrapper {
        height: 400px;
        position: relative;
        overflow: hidden;
        transition: 0.3s linear;

        &::after {
          content: "";
          width: 100%;
          height: 100%;
          background-color: rgba(black, 0.5);
          // z-index: -5;
          position: absolute;
          border-radius: 5px;
          top: 0;
          left: 0;
        }

        & .image {
          width: 100%;
          height: 100%;
          border-radius: 5px;

          & img {
            width: 100%;
            object-fit: cover;
            border-radius: 5px;
            transition: all 0.3s ease-in-out;
            height: 100%;

            @include sm {
            }

            @include md {
            }

            @include lg {
              height: 400px;
            }
          }
        }

        & .imageTextWrapper {
          bottom: 20px;
          left: 20px;
          z-index: 5;
          right: 20px;
          padding: 10px;
          border-radius: 5px;
          // display: none;
          transition: 0.3s ease-in-out;
          transform: translateY(0);
          backdrop-filter: blur(20px);

          @include md {
          }

          @include lg {
            transform: translateY(120%);
          }

          & .imageHead {
            color: white;
            font-weight: 500;
            margin-bottom: 2px;
            font-size: 20px;
            line-height: 120%;

            @include sm {
            }

            @include md {
            }

            @include lg {
              font-size: 30px;
            }
          }

          & .imagePara {
            color: white;
            font-size: 14px;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: 0.6px;
            width: 100%;
            padding-top: 10px;

            @include sm {
            }

            @include md {
            }

            @include lg {
              font-size: 18px;
              width: 80%;
            }
          }
        }
      }
    }
  }
}

.testimonialsSwiper {
  & .swiper-wrapper {
    transition-timing-function: linear;
  }

  & .swiper-slide {
    height: unset !important;
  }
}

.textswiper {
  & .swiper-slide {
    width: fit-content !important;
  }
}

.nuroBtn {
  background-color: #34617d;
  border-radius: 50px;
  border: 1px solid #34617d;
  font-size: 12px;
  color: white;
  font-weight: 600;
  padding: 8px 16px;
  transition: 0.3s linear;

  @include sm {
  }

  @include md {
  }

  @include lg {
    padding: 12px 25px;
    font-size: 14px;
  }

  &:hover {
    opacity: 0.9;

    & svg {
      transform: translateX(5px);
    }
  }

  & svg {
    height: 25px;
    width: 25px;
    margin-left: 3px;
    transition: 0.3s linear;
  }
}

.heroBanner {
  & .heroPill {
    border-radius: 50px;
    border: 1px solid rgb(56, 56, 56);
    font-size: 14px;
    font-weight: 500;
    color: black;
    padding: 8px 15px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    @include sm {
    }

    @include md {
    }

    @include lg {
      margin-bottom: 20px;
      margin-bottom: 10px;
    }

    & svg {
      margin-right: 5px;
    }
  }

  & .heroHeading {
    font-weight: 600;
    color: #1a1a1a;
    text-align: start;
    line-height: 120%;
    font-size: 26px;

    @include sm {
      font-size: 30px;
    }

    @include md {
      font-size: 35px;
    }

    @include lg {
      font-size: 40px;
    }

    @include xl {
      font-size: 50px;
    }

    @include xxl {
      font-size: 55px;
    }

    &.categoryColor {
      color: #517991;
    }
  }

  & .heroHeadingg {
    font-size: 28px;
    font-weight: 600;
  }

  & .heroSubHeading {
    font-size: 15px;
    color: #1a1a1a;
    text-align: start;
    line-height: 150%;
    width: 100%;
    margin-bottom: 20px;

    @include sm {
    }

    @include xl {
      font-size: 15px;
      width: 50%;
    }

    // @include xxl{
    //     font-size: 17px;
    //     width: 50%;
    // }
  }
}

.neuromonicsFluid {
  & .image {
    width: 100%;
    border-radius: 5px;
    height: 100%;

    @include sm {
    }

    @include md {
      height: 400px;
    }

    @include lg {
    }

    @include xl {
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }

  & .paragraph {
    font-size: 13px;
    color: black;
    letter-spacing: 1.4px;

    @include lg {
      font-size: 15px;
    }
  }

  & .heroHeading {
    font-weight: 600;
    color: #1a1a1a;
    text-align: start;
    line-height: 1.2;
    margin-bottom: 20px;
    font-size: 26px;

    @include sm {
      font-size: 36px;
    }

    @include md {
      font-size: 38px;
    }

    @include lg {
      font-size: 60px;
    }

    @include xl {
    }
  }

  & .sectinImg {
    aspect-ratio: 3/2;
  }
}

.servicesFluid {
  & .serviceWrapper {
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 5px;
    z-index: 5;

    &.boxShadow {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100px;
        height: 100px;
        z-index: -5;
        background-image: url(../../common//img//audiologist.svg);
      }

      box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
        rgba(0, 0, 0, 0.05) 0px 5px 10px;
    }

    & .count {
      position: absolute;
      right: 0;
      top: 0px;
      height: 65px;
      width: 140px;
      font-size: 45px;
      line-height: 65px;
      border-radius: 10px;
      background-image: linear-gradient(to right, #517991, white);
      content: "";
      transition: all 0.3s ease;
      padding-left: 15px;
      font-weight: 600;
      color: #e9e9e9;

      &.countt {
        &::before {
          display: none;
        }
      }

      &.counttt {
        &::before {
          display: none;
        }
      }

      &::before {
        counter-increment: my-sec-counter;
        content: counter(my-sec-counter, decimal-leading-zero) " ";
      }
    }

    & .icon {
      padding: 8px;

      & svg {
        width: 60px;
        height: 60px;
      }
    }

    & .heading {
      font-size: 25px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    & .para {
      font-size: 16px;
      color: black;
      letter-spacing: 1.4px;
    }
  }
}

.darkFluid {
  & .image {
    width: 100%;
    height: auto;

    @include sm {
    }

    @include md {
    }

    @include lg {
      height: 700px;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }

  & .para {
    font-size: 16px;
    color: white;
    letter-spacing: 1.4px;

    @include sm {
    }

    @include md {
    }

    @include lg {
    }
  }

  & .percentage {
    color: white !important;
    font-size: 50px !important;
    font-size: clamp(1.33rem, 1.78vw + 0.97rem, 5.25rem);

    @include sm {
    }

    @include md {
    }

    @include lg {
      font-size: 82px !important;
    }
  }

  & .text {
    color: #23506d;
    font-weight: 700;
    font-size: 25px;

    @include sm {
    }

    @include md {
    }

    @include lg {
      font-size: 35px;
    }

    &:last-child {
      color: white;
    }
  }

  & .textt {
    font-size: 17px;
    color: white;
    font-weight: 400;

    @include sm {
    }

    @include md {
    }

    @include lg {
      font-size: 24px;
    }
  }

  & .mainHead {
    color: white;
    font-size: 50px;
    font-weight: 600;

    @include sm {
    }

    @include md {
    }

    @include lg {
      font-size: 100px;
    }
  }

  & .sub {
    font-size: 20px;
    color: white;
    font-weight: 600;

    @include sm {
    }

    @include md {
    }

    @include lg {
      font-size: 25px;
    }
  }

  & .min {
    font-size: 16px;
    color: white;
    font-weight: 400;

    @include sm {
    }

    @include md {
    }

    @include lg {
    }
  }

  & .textWrapper {
    & .textOne {
      font-size: 42px;
      font-weight: 600;
      color: white;
      white-space: nowrap;
      text-transform: uppercase;
      padding-block: 10px;
    }
  }
}

.hoursFluid {
  & .image {
    width: 100%;
    height: 500px;
    border-radius: 5px;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }

  & .cardWrapper {
    height: 100;

    &.cardWrapperr {
      position: relative;
      z-index: 5;
      border-radius: 10px;

      & .five {
        &::after {
          content: "";
          position: absolute;
          top: 8px;
          left: -18px;
          width: 109%;
          height: 95%;
          background-color: #23506d;
          border-radius: 10px;
          z-index: -5;
          transform: rotate(10deg);
        }

        padding: 20px;
        border-radius: 10px;
        background-color: white;
      }
    }

    & .cardHead {
      font-size: 24px;
      font-weight: 400;
      color: black;
    }

    & .cardSubHead {
      font-size: 18px;
      font-weight: 500;
      color: black;
    }

    & .cardPara {
      font-size: 16px;
      font-weight: 500;
      color: black;
    }
  }
}

.provideFluid {
  & .heroHeading {
    font-weight: 600;
    color: #1a1a1a;
    text-align: start;
    font-size: 26px;

    @include sm {
    }

    @include md {
    }

    @include lg {
      font-size: 50px;
    }
  }

  & .image {
    &.one {
      width: 145px;
      height: 145px;
      margin-left: 0px;
      margin-top: -25px;

      @include sm {
        margin-left: -26px;
        margin-top: -47px;
      }

      @include md {
        width: 200px;
        height: 300px;
      }

      @include lg {
        width: 200px;
        height: 300px;
        margin-top: -80px;
        margin-left: -54px;
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }

    &.two {
      width: 145px;
      height: 145px;
      margin-top: 20px;
      margin-left: -25px;

      @include sm {
      }

      @include md {
        height: 300px;
        width: 80%;

        & img {
          width: 80%;
        }
      }

      @include lg {
        height: 450px;
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }

    &.three {
      width: 145px;
      height: 145px;
      margin-top: 20px;

      @include sm {
        margin-top: 90px;
      }

      @include md {
      }

      @include lg {
        width: 200px;
        height: 300px;
        margin-top: 0;
        margin-left: 0;
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }
  }
}

.serviceFluid {
  & .cardBox {
    @include md {
      width: 80%;
      overflow-y: auto;
      display: flex;
      flex-wrap: nowrap;
    }

    @include md {
      width: 60%;
      overflow-y: auto;
      display: flex;
      flex-wrap: nowrap;
    }

    @include xl {
      display: flex;
      overflow-y: auto;
      width: 33.33%;
    }

    & .cardWrapper {
      &:hover {
        & .cardImg {
          & img {
            transform: scale(1.2);
          }
        }

        & .cardTxtWrapper {
          &::after {
            opacity: 1;
          }
        }
      }

      & .cardImg {
        width: 100%;
        height: 300px;
        position: relative;
        overflow: hidden;
        border-radius: 5px;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(black, 0.7);
          border-radius: 5px;
          width: 100%;
          height: 100%;
          z-index: -5;
        }

        & img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: 0.3s linear;
        }
      }

      & .cardTxtWrapper {
        background-color: #517991;
        width: 80%;
        margin-top: -30%;
        border-radius: 5px;
        z-index: 5;
        position: relative;
        height: 200px;
        padding: 20px;

        @include sm {
          height: 155px;
          margin-top: -20%;
        }

        @include md {
          height: 210px;
          margin-top: -30%;
        }

        @include xl {
          height: 235px;
          padding: 25px;
        }

        &::after {
          content: "";
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: white;
          background-image: url(../img/rytArrow.svg);
          background-repeat: no-repeat;
          background-size: contain;
          position: absolute;
          top: -24px;
          right: 18px;
          opacity: 0;
          transition: 0.3s linear;
        }

        & .head {
          font-size: 16px;
          font-weight: 600;
          color: white;
          line-height: normal;
          letter-spacing: 1.5px;

          @include sm {
            font-size: 20px;
          }
        }

        & .para {
          font-size: 14px;
          line-height: 120%;
          font-weight: 400;
          letter-spacing: 1.4px;
          color: white;
        }
      }
    }
  }
}

.blogFluid {
  & .blogCrdWrapper {
    border: 1px solid rgb(204, 200, 200);
    border-radius: 5px;
    height: 100%;

    &:hover {
      cursor: pointer;

      & .blogImg {
        & img {
          transform: scale(1.1);
        }
      }

      & .textWrapper {
        & .head {
          color: #34617d;
        }
      }
    }

    & .blogImg {
      width: 100%;
      height: 250px;
      border-radius: 5px 5px 0 0;
      position: relative;
      overflow: hidden;
      z-index: 5;
      border: 2px solid rgba(81, 121, 145, 0.231) !important;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(black, 0.8);
        z-index: -5;
      }

      & span {
        position: absolute;
        content: "";
        top: 20px;
        left: 20px;
        background-color: white;
        width: 60px;
        height: 60px;
        font-size: 16px;
        border-radius: 5px;
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px 5px 0 0;
        transition: 0.3s linear;
      }
    }

    & .author {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: -10px;

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    & .textWrapper {
      padding: 20px 20px 20px 20px;

      & .head {
        font-size: 18px;
        font-weight: 700;
        color: #1a1a1a;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        transition: 0.3s linear;
      }

      & .subHead {
        font-size: 20px;
        font-weight: 500;
        color: #1a1a1a;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      & .para {
        font-size: 16px;
        color: black;
        letter-spacing: 1.4px;
        margin-top: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      & .readBtn {
        background-color: #34617d;
        border-radius: 50px;
        border: 1px solid #34617d;
        font-size: 12px;
        color: white;
        font-weight: 600;
        transition: 0.3s linear;
        width: 114px !important;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;

        &:hover {
          opacity: 0.9 !important;

          & svg {
            transform: translateX(5px);
          }
        }

        @include sm {
        }

        @include md {
        }

        @include lg {
          font-size: 12px;
        }

        & svg {
          height: 25px;
          width: 25px;
          margin-left: 3px;
          transition: 0.3s linear;
        }
      }
    }
  }
}

.blogInnerFluid {
  & .paddingInline {
    @include xl {
      --bs-gutter-x: 80px !important;

      & > * {
      }
    }
  }

  & .blogCradWrapper {
    &.giveSpace {
      @include lg {
        margin-bottom: 0px !important;
      }

      @include xl {
        margin-bottom: 20px !important;
      }

      @include xxl {
        margin-bottom: 65px !important;
      }
    }

    & .blogImg {
      border-radius: 5px !important;
      position: relative !important;
      z-index: -5 !important;
      overflow: hidden !important;
      border: 2px solid #5179913b !important;

      &.blogImg1 {
        aspect-ratio: 1/1 !important;

        @include md {
          aspect-ratio: 1/1.2 !important;
        }
      }

      &.blogImg2 {
        aspect-ratio: 1/1 !important;

        @include md {
          aspect-ratio: 1/0.8 !important;
        }
      }

      & .authorRow {
        position: absolute !important;
        bottom: 20px !important;
        left: 20px !important;
        backdrop-filter: blur(10px) !important;
        padding: 15px !important;
        z-index: 5 !important;
        background-color: #0000002f !important;
        right: 20px !important;

        & .author {
          border-radius: 25px !important;
          font-size: 20px !important;
          font-weight: 500 !important;
          color: white !important;
        }

        & .authorList {
          font-size: 16px !important;
          font-weight: 600 !important;
          color: white !important;
          font-style: italic !important;
        }
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }

    & .title {
      &.homeTitle {
        font-size: 20px;
      }

      margin-bottom: 10px;
      color: black;
      letter-spacing: 1.4px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-transform: capitalize;
      line-height: 1.3em;
      cursor: pointer;
      transition: 0.3s linear;

      &:hover {
        color: #34617d;
      }

      @include sm {
      }

      @include md {
        font-size: 1.2em;
      }

      @include lg {
        font-size: 2em;
      }
    }

    & .nuroBtnn {
      background-color: transparent;
      border-bottom: 1px solid;
      overflow: hidden;
      color: #34617d;
      font-size: 18px;
      cursor: pointer;
      padding: 0;
    }
  }
}

.paddingTopHeader {
  padding-top: 50px;

  @include sm {
    padding-top: 144px;
  }
}

.blogBtn {
  background-color: transparent;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #517991;

  & svg {
    width: 28px;
    height: 28px;
    color: white;
  }
}

.blogInnerFluid {
  & .paddingInline {
    @include xl {
      --bs-gutter-x: 80px !important;

      & > * {
      }
    }
  }

  & .blogCradWrapper {
    &.giveSpace {
      @include sm {
      }

      @include lg {
        margin-bottom: 0px !important;
      }

      @include xl {
        margin-bottom: 20px !important;
      }

      @include xxl {
        margin-bottom: 65px !important;
      }
    }

    & .blogImg {
      border-radius: 5px;
      position: relative;
      z-index: -5;
      overflow: hidden;
      border: 2px solid rgba(81, 121, 145, 0.231) !important;

      &.blogImg1 {
        aspect-ratio: 1/1;

        @include sm {
        }

        @include md {
          aspect-ratio: 1/1.2;
        }
      }

      &.blogImg2 {
        aspect-ratio: 1/1;

        @include sm {
        }

        @include md {
          aspect-ratio: 1/0.8;
        }
      }

      & .authorRow {
        position: absolute;
        bottom: 20px;
        left: 20px;
        backdrop-filter: blur(10px);
        padding: 15px;
        z-index: 5;
        right: 20px;

        & .author {
          border-radius: 25px;
          font-size: 20px;
          font-weight: 500;
          color: white;
        }

        & .authorList {
          font-size: 16px;
          font-weight: 600;
          color: white;
          font-style: italic;
        }
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }

    & .title {
      margin-bottom: 10px;
      color: black;
      letter-spacing: 1.4px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-transform: capitalize;
      line-height: 1.3em;
      cursor: pointer;
      transition: 0.3s linear;

      &:hover {
        color: #34617d;
      }

      @include sm {
      }

      @include md {
        font-size: 1.2em;
      }

      @include lg {
        font-size: 2em;
      }

      @include xl {
      }

      @include xxl {
      }
    }

    & .nuroBtnn {
      background-color: transparent;
      border-bottom: 1px solid;
      overflow: hidden;
      color: #34617d;
      font-size: 18px;
      cursor: pointer;
      padding: 0;
    }
  }
}

.blurLayer {
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(black, 0.5);
    width: 100%;
    height: 100%;
  }
}

.blogDetailFluid {
  & .heroHeadingBlog {
    text-transform: capitalize;
    letter-spacing: 1.2px;
    line-height: 1.3;
    color: black;
    margin-bottom: 15px;
    font-size: 15px;

    @include md {
      font-size: 35px !important;
      margin-bottom: 25px;
    }

    @include lg {
      font-size: 35px !important;
      margin-bottom: 32px;
    }

    @include xl {
      font-size: 40px !important;
    }

    @include xxl {
      font-size: 50px !important;
    }
  }

  & .blogAuthor {
    font-size: 18px;
  }

  & .date {
    font-size: 18px;
    font-weight: 500;
    color: black;
    margin-bottom: 15px;

    @include md {
      margin-bottom: 32px;
    }

    @include lg {
      margin-bottom: 52px;
    }
  }

  & .blogHeading {
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 20px;
    color: black;
    font-size: 16px;

    @include sm {
      font-size: 22px;
    }
  }

  & .blogPara {
    font-size: 16px;
    color: black;
    letter-spacing: 1.4px;
  }

  & .blogImg {
    height: auto;
    border: 2px solid rgba(81, 121, 145, 0.231) !important;

    @include sm {
      height: 400px;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }

  & .dataImg {
    width: 100%;
    height: 100%;
    margin-block: 30px;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  & .blogLink {
    font-weight: 500;
    color: black;
    text-decoration: none;
    font-size: 15px;

    @include md {
      font-size: 28px;
    }

    & svg {
      width: 20px;
      height: 20px;

      @include md {
        width: 25px;
        height: 25px;
      }
    }
  }
}

.journalsFluid {
  & .JournalsCard {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    height: 100%;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-image: url(../../common/img/bgggggg.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
      z-index: -5;
      border-radius: 4px;
    }

    & .title {
      font-size: 16px;
      font-weight: 600;
    }

    & .authors {
      font-size: 13px;
      font-weight: 500;
      color: black;
      margin-top: 2px;

      & span {
        font-size: 14px;
        font-weight: 600;
        color: black;
        opacity: 0.6;
        margin-right: 5px;
      }
    }

    & .timing {
    }

    & .link {
      text-decoration: none;
      font-size: 14px;
      color: #34617d;
      font-weight: 600;
      width: 100%;
      // border-bottom: 1px solid;
      padding-bottom: 2px;
    }
  }
}

.serviceContainer {
  & .cardBox {
    overflow: unset;

    & .cardWrapper {
      position: relative;

      & .cardImg {
        width: 80%;
        height: 580px !important;

        & img {
        }
      }
    }

    & .cardTxtWrapper {
      position: absolute !important;
      right: -45px;
      width: 50% !important;
      height: 300px !important;
      padding: 40px;
      top: 50%;
      transform: translateY(-50%);
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.09);
      border: 1px solid #fff;
      opacity: 1;
      backdrop-filter: blur(8px);
      -webkit-backdrop-filter: blur(8px);
      gap: 1rem;
      margin-top: 0 !important;
      position: relative;
      background: rgba(255, 255, 255, 0.5);
      backface-visibility: hidden;
      z-index: 2;
      background: rgba(255, 255, 255, 0.5) !important;

      &::after {
        display: none !important;
      }

      & .head {
        font-size: 28px !important;
      }

      & .para {
        font-size: 18px;
        color: black;
        letter-spacing: 1.4px;
      }
    }
  }

  & .blogBtns {
    & .blogNext {
      background-color: #23506d;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      & svg {
        color: white;
      }
    }

    & .blogPrev {
      background-color: #23506d;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      & svg {
        color: white;
      }
    }
  }
}

.aboutPadding {
  padding-top: 70px !important;

  @include md {
    padding-top: 75px !important;
  }

  @include lg {
    padding-top: 85px !important;
  }
}

.aboutHomeSec {
  background-image: url(../img/mainBack4.jpg);
  background-size: cover;
  background-repeat: no-repeat;

  & .heading {
    font-size: 26px;
    font-weight: 700;
    line-height: 120%;
    color: #000;

    @include sm {
      font-size: 34px;
    }

    @include md {
      font-size: 37px;
    }

    @include lg {
      font-size: 46px;
    }

    @include xl {
      font-size: 48px;
    }
  }

  & .description {
    font-size: 13px;
    color: black;
    letter-spacing: 1.4px;

    @include md {
      font-size: 14px;
    }

    @include xl {
      font-size: 15px;
    }

    // @include xxl{
    //     font-size: 17px;
    // }
  }

  & .sideImg {
    aspect-ratio: 3/2;
    border-radius: 5px;

    & img {
      object-fit: cover;
      transition: all 0.5s ease-in-out;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  & .sideLower {
    aspect-ratio: 1/1.1;
    border-radius: 5px;

    & img {
      object-fit: cover;
      transition: all 0.5s ease-in-out;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  & .leftUpper {
    aspect-ratio: 1/1.1;
    border-radius: 5px;

    & img {
      object-fit: cover;
      transition: all 0.5s ease-in-out;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .leftLower {
    aspect-ratio: 3/2;
    border-radius: 5px;

    & img {
      object-fit: cover;
      transition: all 0.5s ease-in-out;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

.cardThreeSection {
  & .mainCard {
    padding: 20px;
    position: relative;
    transition: all 0.3s ease-in-out;

    @include md {
      padding: 40px 30px;
    }

    & .icon {
      height: 30px;
      width: 30px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #517991;

      @include md {
        height: 40px;
        width: 40px;
      }

      &::before {
        content: "";
        position: absolute;
        background-color: rgba(231, 15, 71, 0.03);
        height: 60px;
        width: 60px;
        border-radius: 50%;

        @include md {
          height: 70px;
          width: 70px;
        }
      }
    }

    & .cardheading {
      font-size: 18px;
      font-weight: 700;
      line-height: 120%;

      @include sm {
        font-size: 20px;
      }

      @include md {
        font-size: 22px;
      }

      @include lg {
        font-size: 25px;
      }
    }

    & .description {
      font-size: 14px;
      line-height: 122%;
      letter-spacing: 1.3px;

      @include md {
        font-size: 15px;
      }

      // @include xxl{
      //     font-size: 18px;
      // }
    }

    & .cardButton {
      position: absolute;
      bottom: -20px;
      left: 50%;
      transform: translateX(-50%);
      transition: all 0.3s ease-in-out;

      & .cardbtnbtn {
        background-color: #517991;
        color: white;
        font-size: 14px;
        font-weight: 600;
        padding: 10px 20px;
        transition: all 0.5s ease-in-out;

        &:hover {
          background-color: black;
        }
      }
    }

    &:hover {
      & .cardButton {
        bottom: 0;
        padding-bottom: 10px;

        & .cardbtnbtn {
        }
      }
    }
  }
}

.missionSec {
  & .descripton {
    width: 100% !important;
    margin-bottom: 0 !important;
    letter-spacing: 1.4px;

    & i {
      letter-spacing: 1.5px;
      color: #000;
    }
  }

  & .missionImg {
    aspect-ratio: 4/2;

    @include lg {
      aspect-ratio: 3/2;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }
}

.contactSection {
  & .formSide {
    & .contactForm {
      &:focus {
        border-color: #e0e1e3 !important;
      }
    }

    & .formTextarea {
      height: 100px;

      &:focus {
        border-color: #e0e1e3 !important;
      }
    }
  }

  & .ContactImg {
    aspect-ratio: 3/2;
    overflow: hidden;
    border-radius: 5px;

    & img {
      object-fit: cover;
    }
  }
}

.faqSection {
  background-color: #e1ebf496;

  & .accordion {
    & .accordion-item {
      margin-block: 5px;
      border-bottom: 1px solid #d4bb8f !important;
      background-color: #e1ebf496;
      border: 0;
      border-radius: 0;

      & .accordion-header {
        padding-block: 10px;

        & .accordion-button {
          background-color: #e1ebf496;
          font-size: 18px !important;
          font-weight: 700 !important;
          box-shadow: none !important;

          &:not(.collapsed) {
            background-color: transparent !important;
            color: #23506d;
          }
        }
      }
    }
  }
}

.tinnitusSec {
  & .imgSide {
    aspect-ratio: 2/2;

    & img {
      object-fit: cover;
    }

    @include sm {
      aspect-ratio: 3/2;
    }

    @include md {
      aspect-ratio: 4/2;
    }

    @include lg {
      aspect-ratio: 2/2;
    }

    @include xxl {
      aspect-ratio: 2.5/2;
    }
  }
}

.mapImgChart {
  aspect-ratio: 6/2;
  padding-inline: 175px;

  & img {
    object-fit: contain;
  }
}

.mapImgSecondChart {
  aspect-ratio: 4/2;

  & img {
    object-fit: contain;
  }
}

.navDesien {
  border: 2px solid #23506d;
  padding: 3px 10px;
  border-radius: 50px;
  display: inline-block;

  @include xl {
    border: 0;
    padding: 0;
  }
}

.mapSection {
  background-color: #fdf7e4;

  & .mapImg {
    aspect-ratio: 4/2;

    @include lg {
      aspect-ratio: 3/2;
    }

    & img {
      object-fit: cover;
    }
  }

  & .clinicHeading {
    font-size: 26px;
    font-weight: 700;
    line-height: 120%;
    color: #000;

    @include sm {
      font-size: 28px;
    }

    @include lg {
      font-size: 37px;
    }

    @include xl {
      font-size: 45px;
    }

    @include xxl {
      font-size: 46px;
    }
  }

  & .descripton {
    width: 100%;
    margin-bottom: 0 !important;
    font-size: 14px;

    @include sm {
      font-size: 15px;
    }

    // @include xxl{
    //     font-size: 20px;
    // }
  }
}

.numroHomeSec {
  background-image: url(../img/eight.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 0;

  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    background-color: #00000070;
    height: 100%;
    width: 100%;
    z-index: -1;
  }

  & .heading {
    font-size: 26px;
    font-weight: 700;
    line-height: 120%;
    color: white;
    z-index: 1;

    @include sm {
      font-size: 34px;
    }

    @include md {
      font-size: 37px;
    }

    @include lg {
      font-size: 48px;
    }
  }

  & .description {
    font-size: 13px;
    color: white;
    letter-spacing: 1.4px;
    z-index: 1;

    @include md {
      font-size: 14px;
    }

    @include lg {
      font-size: 16px;
    }
  }
}

.mainTxt {
  font-size: 15px;
  color: #1a1a1a;
}

.cardSevensection {
  background-color: #f0f8ff;

  & .mainCard {
    & .cardImg {
      aspect-ratio: 11/8;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
      border-radius: 6px 6px 0 0px;

      @include lg {
        border-radius: 6px 0 0 6px;
        aspect-ratio: 5/8;
      }

      & img {
        object-fit: cover;
        transition: all 0.5s ease-in-out;
      }
    }

    & .dateTime {
      font-size: 14px;
      font-weight: 500;
      opacity: 0.7;
      padding-bottom: 5px;
      padding-top: 10px;

      @include lg {
        padding-top: 10px;
      }
    }

    & .section {
      font-size: 15px;
      font-weight: 500;
      opacity: 0.7;
      padding-bottom: 5px;
    }

    & .cardHeading {
      font-size: 15px;
      font-weight: 600;
      padding-bottom: 10px;
      line-height: 140%;
      transition: all 0.3s ease-in-out;

      @include sm {
        font-size: 16px;
      }

      @include md {
        font-size: 17px;
      }

      @include xl {
        font-size: 20px;
      }
    }

    & .description {
      font-size: 14px;
      opacity: 0.7;
      padding-inline: 5px;
      line-height: 140%;

      @include md {
        font-size: 15px;
      }

      @include xl {
        font-size: 16px;
      }
    }

    & .readbtn {
      & .readBtnanc {
        color: black;
        transition: all 0.3s ease-in-out;
        font-weight: 600;

        & .arrowIcon {
          filter: brightness(0);
          display: inline-flex;
          transition: all 0.3s ease-in-out;
        }
      }

      &:hover {
        & .readBtnanc {
          color: #23506d;

          & .arrowIcon {
            filter: none;
            transform: translateX(10px);
          }
        }
      }
    }

    &:hover {
      & .cardHeading {
        color: #23506d;
      }
    }

    &:hover {
      & .cardImg {
        & img {
          transform: scale(1.1);
        }
      }
    }
  }
}

.cardBackColor {
  background-color: #f0f8ff;

  & .serviveCard {
    background-color: white;
  }
}

.testimonialWrapper .stars .link {
  text-decoration: none;
  font-size: 24px;
  color: #34617d;
  font-weight: 600;
  width: 100%;
  border-bottom: 1px solid;
  padding-bottom: 2px;
}

.findSection {
  background-color: rgba(240, 245, 249, 1);
  padding-block: 20px;

  @include sm {
    padding-block: 50px;
  }

  @include md {
    padding-block: 40px;
  }

  @include lg {
    padding-block: 40px;
  }

  & .heading {
    font-size: 24px;
    line-height: 120%;
    font-weight: 700;

    @include sm {
      font-size: 28px;
    }

    @include md {
      font-size: 32px;
    }

    @include lg {
      font-size: 36px;
    }
  }

  & .description {
    font-size: 15px;
    font-weight: 600;
    line-height: 140%;

    @include sm {
      font-size: 16px;
    }

    @include md {
      font-size: 18px;
    }

    @include lg {
      font-size: 20px;
    }
  }

  & .locationDiv {
    border: 1px solid #23506d;
    border-radius: 50px;
    padding: 12px 22px;
    transition: all 0.3s ease-in-out;
    max-width: 240px;
    margin-inline: auto;

    @include sm {
      padding: 14px 10px;
    }

    @include md {
      padding: 17px;
    }

    & .locationBtn {
      font-size: 17px;
      font-weight: 500;
      line-height: 120%;
      text-decoration: none;
      color: #23506d;
      transition: all 0.3s ease-in-out;
      background-color: transparent !important;
    }

    &:hover {
      background-color: #23506d;

      & .locationBtn {
        color: #797979;
      }
    }

    & .locationSvg {
      height: 20px;
      width: 20px;
      display: flex;
    }
  }

  & .findpageInputGroup {
    border: 1px solid #517991;
    border-radius: 5px;
    background-color: #fff;
    align-items: center !important;

    & .formControl {
      border: 0;
      box-shadow: none;
      font-size: 16px;
      font-weight: 500;
      padding: 10px 6px;

      @include sm {
        padding: 12px 8px;
      }

      @include md {
        padding: 14px 10px;
      }

      @include lg {
        font-size: 18px;
      }
    }
  }

  .searchSvg {
    height: 20px;
    width: 20px;
    display: flex;
    color: #fff;

    @include sm {
      height: 25px;
      width: 25px;
    }
  }

  & .searchBtn {
    background-color: #23506d !important;
    border-radius: 2px !important;
    padding: 10px 14px !important;

    & .searchIconBtn {
      display: flex;
      background-color: transparent !important;
    }
  }
}

.searchSection {
  border: 1px solid #23506d;
  margin: 10px;
  padding: 0px !important;
  border-radius: 5px;

  & .heading {
    font-size: 24px;
    line-height: 120%;
    font-weight: 700;

    @include sm {
      font-size: 28px;
    }

    @include md {
      font-size: 32px;
    }

    @include lg {
      font-size: 36px;
    }
  }

  & .description {
    font-size: 15px;
    font-weight: 600;
    line-height: 140%;

    @include sm {
      font-size: 16px;
    }

    @include md {
      font-size: 18px;
    }

    @include lg {
      font-size: 20px;
    }
  }

  & .locationDiv {
    // border: 1px solid #23506D;
    // border-radius: 50px;
    // padding: 12px 22px;
    // transition: all .3s ease-in-out;
    // max-width: 240px;
    margin-inline: auto;

    @include sm {
      // padding: 14px 10px;
    }

    @include md {
      // padding: 17px;
    }

    & .locationBtn {
      font-size: 17px;
      font-weight: 500;
      line-height: 120%;
      text-decoration: none;
      color: #848484;
      transition: all 0.3s ease-in-out;
      background-color: transparent !important;
      height: 50px;
      width: 50px;
      padding: 5px;
    }

    &:hover {
      // background-color: #23506D;
      & .locationBtn {
        color: #23506d;
      }
    }

    & .locationSvg {
      height: 20px;
      width: 20px;
      display: flex;
    }
  }

  & .findpageInputGroup {
    // border: 1px solid #517991;
    border-radius: 5px;
    background-color: #fff;
    align-items: center !important;

    & .formControl {
      border: 0;
      box-shadow: none;
      font-size: 16px;
      font-weight: 500;
      padding: 10px 6px;

      @include sm {
        padding: 12px 8px;
      }

      @include md {
        padding: 14px 0px;
      }

      @include lg {
        font-size: 16px;
      }
    }
  }

  .searchSvg {
    height: 20px;
    width: 20px;
    display: flex;
    color: #fff;

    @include sm {
      height: 18px;
      width: 18px;
    }
  }

  & .searchBtn {
    background-color: #23506d !important;
    border-radius: 2px !important;
    padding: 12px 0px !important;

    & .searchIconBtn {
      display: flex;
      background-color: transparent !important;
    }
  }
}

.mapLocSection {
  & .mapContainer {
    border: 1px solid #34617d;
  }

  overflow: hidden;

  & .mapDiv {
    width: 100%;
  }

  & .MapDetails {
    height: 400px;
    overflow-y: auto;

    & .mapCard {
      border-bottom: 1px solid #34617d;
      padding: 30px 25px;

      & .mapHead {
        font-size: 26px;
        font-weight: 600;
        line-height: 120%;
      }

      & .mapAddress {
        font-size: 17px;
        font-weight: 500;
        line-height: 120%;
      }

      & .distanceDiv {
        display: flex;
        align-items: center;
        gap: 5px;
        padding-block: 20px;

        & .distIcon {
          height: 20px;
          width: 20px;
          color: #34617d;
          display: flex;
        }

        & .distNumber {
          font-size: 16px;
          font-weight: 600;
          line-height: 100%;
        }
      }

      & .telPhone {
        & .telAnc {
          font-size: 16px;
          color: #000;
          padding-left: 3px;
        }
      }

      & .mapBtn {
        margin-top: 15px;

        & .visitBtn {
          padding: 8px 15px;
        }
      }
    }
  }
}

.gm-style-iw {
  width: 260px !important;
  padding: 20px !important;
}

.gm-style-iw-d {
  padding: 0 !important;
}

.mapHeading {
  font-size: 18px;
  font-weight: 600;
  color: #000;
}

.mapDescription {
  font-size: 16px;
  padding-top: 10px;
}

.mapEmail {
  padding-top: 10px;
  font-size: 14px;
  line-height: 100%;
}

.mapPhone {
  padding-top: 10px;
  font-size: 14px;
  line-height: 100%;
}

.labelMap {
  font-size: 14px;
  line-height: 100%;
}

.mapButton {
  border: 1px solid #34617d;
  padding: 8px 12px;
  border-radius: 50px;
  margin-top: 15px;
  width: 130px;
  background-color: #23506d;
}

.mapBtnanc {
  color: #fff !important;
  text-decoration: none !important;
}

.svgMapIcon {
  height: 15px;
  width: 15px;
  display: flex;
  align-self: center;
}

.mapAncTxt {
  font-size: 14px;
  line-height: 120%;
}

button.gm-ui-hover-effect {
  width: 40px;
  height: 45px;
}

.blogUl {
  padding-left: 40px;
}

.ReactModalPortal {
  & .ReactModal__Content {
    width: 100% !important;

    @include sm {
      width: 50% !important;
    }

    @include md {
      width: 40% !important;
    }

    @include lg {
      width: 30% !important;
    }
  }
}

.modalRound {
  border-radius: 6px !important;
}

.enquiretxt {
  text-transform: uppercase;
  font-weight: 500;
  padding-left: 0;
  font-size: 22px;
  padding-bottom: 5px;
}

.enqpara {
  letter-spacing: 1.3px;
}

.roundedCircle {
  border-radius: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  background-color: #00000062;
  padding: 25px !important;

  @include md {
    padding: 40px 25px !important;
  }
}

.maptextarea {
  height: 60px !important;

  @include xxl {
    height: 330px !important;
  }
}

.roundedSlideThree {
  padding-top: 50px !important;

  @include md {
    padding-top: 150px !important;
  }
}

.menuColorClass {
  & svg {
    color: #34617d !important;
  }
}

.roundedNumber {
  font-size: 30px;
  font-weight: 700;
  height: 50px;
  width: 50px;
  border: 2px solid #3d3d3d;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -24px;
  left: 10%;
  transform: translateX(-50%);
  backdrop-filter: blur(10px);
  background-color: #00000062;

  @include md {
    height: 70px;
    width: 70px;
    top: -35px;
    font-size: 35px;
    left: 14%;
  }
}

.signBtnFind {
  max-width: 200px !important;
}

.textItalic {
  color: #000 !important;
  font-weight: 600 !important;
}

.letter-spacing {
  letter-spacing: 1.3px !important;
}

.enqnumLogo {
  height: 38px;
  width: 100px;

  & img {
    height: 100%;
    width: 100%;
  }
}

.testimonialFluid {
  // width: max-content;

  & .testimonialContainer {
    position: relative;
  }

  & .maintesti {
    max-width: max-content;
    width: max-content;

    &:hover > * {
      animation-play-state: paused !important;
    }

    & .mainsectiontesti {
      animation: marqueeHorizontal 40s linear infinite;
      transition: all 5s ease-in-out;

      & .testimonialWrapper {
        height: 100% !important;
        max-width: 400px !important;
        width: 100%;
        border-radius: 5px;
        padding: 20px;
        background-color: #eae6e1;

        & .starUl {
          & .starLi {
            text-decoration: none;
            list-style: none;

            & svg {
              width: 20px;
              height: 20px;
            }
          }
        }

        & .para {
          font-size: 14px;
          color: black;
          font-weight: 300;
          line-height: 1.6;
          margin-block: 20px;
          letter-spacing: 0.5px;
        }

        & .user {
          width: 40px;
          height: 40px;
          border-radius: 50%;

          & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        & .userName {
          font-size: 18px;
          font-weight: 600;
          color: black;
        }

        & .designation {
          font-size: 12px;
          color: black;
        }
      }
    }
  }

  @include sm {
    & .maintesti {
      max-width: max-content;
      width: max-content;

      & .mainsectiontesti {
        animation: marqueeHorizontal 40s linear infinite;

        & .testimonialWrapper {
          height: 100% !important;
          min-width: 450px !important;
          width: 100%;
          border-radius: 5px;
          padding: 20px;
          background-color: #eae6e1;
          gap: 20px;

          & .starUl {
            & .starLi {
              text-decoration: none;
              list-style: none;

              & svg {
                width: 20px;
                height: 20px;
              }
            }
          }

          & .para {
            font-size: 16px;
            color: black;
            font-weight: 300;
            line-height: 1.6;
            margin-block: 20px;
            letter-spacing: 0.5px;
          }

          & .user {
            width: 50px;
            height: 50px;
            border-radius: 50%;

            & img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 50%;
            }
          }

          & .userName {
            font-size: 24px;
            font-weight: 600;
            color: black;
          }

          & .designation {
            font-size: 12px;
            color: black;
          }
        }
      }
    }
  }
}

// blog section start

.blogSection {
  & .blogscrrolInner {
    max-width: max-content;
    width: max-content;

    &:hover > * {
      animation-play-state: paused !important;
    }

    & .innerblogmain {
      animation: marqueeHorizontal 40s linear infinite;
      transition: all 5s ease-in-out;

      & .blogcard {
        height: 100% !important;
        max-width: 385px !important;
        width: 100%;

        & .blogImg {
          border-radius: 5px;
          position: relative;
          z-index: -5;
          overflow: hidden;
          border: 2px solid rgba(81, 121, 145, 0.231) !important;

          &.blogImg1 {
            aspect-ratio: 1/1;

            @include sm {
            }

            @include md {
              aspect-ratio: 1/1.2;
            }
          }

          &.blogImg2 {
            aspect-ratio: 1/1;

            @include sm {
            }

            @include md {
              aspect-ratio: 1/0.8;
            }
          }

          & .authorRow {
            position: absolute;
            bottom: 20px;
            left: 20px;
            backdrop-filter: blur(10px);
            padding: 15px;
            z-index: 5;
            right: 20px;

            & .author {
              border-radius: 25px;
              font-size: 20px;
              font-weight: 500;
              color: white;
            }

            & .authorList {
              font-size: 16px;
              font-weight: 600;
              color: white;
              font-style: italic;
            }
          }

          & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px;
          }
        }

        & .title {
          margin-bottom: 10px;
          color: black;
          letter-spacing: 1.4px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-transform: capitalize;
          line-height: 1.3em;
          cursor: pointer;
          transition: 0.3s linear;

          &:hover {
            color: #34617d;
          }

          @include sm {
          }

          @include md {
            font-size: 1.2em;
          }

          @include lg {
            font-size: 2em;
          }

          @include xl {
          }

          @include xxl {
          }
        }

        & .nuroBtnn {
          background-color: transparent;
          border-bottom: 1px solid;
          overflow: hidden;
          color: #34617d;
          font-size: 18px;
          cursor: pointer;
          padding: 0;
        }
      }
    }
  }
}

.whiteSvgbtn {
  & svg {
    color: white !important;
  }
}

@keyframes marqueeHorizontal {
  0% {
    transform: translateZ(0);
    visibility: visible;
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

.italicfont {
  font-style: italic;
  opacity: 0.5 !important;
  font-weight: 600 !important;
  padding-block: 5px;
}

.tinnitusDesciption {
  font-size: 15px !important;
}

.privacylisting {
  & ::marker {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
    color: var(--bs-heading-color);
  }
}

.card-table {
  border-spacing: 0px 10px !important;
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 100vh;
  flex-direction: column;
}
.custom-alert {
  // margin-top:25px;
  background-color: #fff;
  font-size: 25px;
  font-family: sans-serif;
  text-align: center;
  width: 400px;
  height: 260px;
  padding-top: 180px;
  position: relative;
  border: 1px solid #efefda;
  border-radius: 2%;
  box-shadow: 0px 0px 3px 1px #ccc;
  margin-bottom: 20px;
}
.custom-alert::before {
  width: 100px;
  height: 100px;
  position: absolute;
  border-radius: 100%;
  inset: 40px 0px 0px 145px;
  font-size: 60px;
  line-height: 100px;
  border: 5px solid gray;
  animation-name: reveal;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
}
.custom-alert > .custom-alert-body {
  opacity: 0;
  animation-name: reveal-message;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-delay: 1.5s;
  animation-fill-mode: forwards;
}
@keyframes reveal-message {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.success {
  color: green;
}
.success::before {
  content: "✓";
  background-color: #eff;
  box-shadow: 0px 0px 12px 7px rgba(200, 255, 150, 0.8) inset;
  border: 5px solid green;
}
.error {
  color: red;
}
.error::before {
  content: "✗";
  background-color: #fef;
  box-shadow: 0px 0px 12px 7px rgba(255, 200, 150, 0.8) inset;
  border: 5px solid red;
}
@keyframes reveal {
  0% {
    border: 5px solid transparent;
    color: transparent;
    box-shadow: 0px 0px 12px 7px rgba(255, 250, 250, 0.8) inset;
    transform: rotate(1000deg);
  }
  25% {
    border-top: 5px solid gray;
    color: transparent;
    box-shadow: 0px 0px 17px 10px rgba(255, 250, 250, 0.8) inset;
  }
  50% {
    border-right: 5px solid gray;
    border-left: 5px solid gray;
    color: transparent;
    box-shadow: 0px 0px 17px 10px rgba(200, 200, 200, 0.8) inset;
  }
  75% {
    border-bottom: 5px solid gray;
    color: gray;
    box-shadow: 0px 0px 12px 7px rgba(200, 200, 200, 0.8) inset;
  }
  100% {
    border: 5px solid gray;
    box-shadow: 0px 0px 12px 7px rgba(200, 200, 200, 0.8) inset;
  }
}
