

// .selectLabel{
//     font-size: 14px;
//     color: $black;
//     font-weight: 500;
// }
// .SelectBox{
//     font-size: 14px;
//     color: $black;
//     font-weight: 500;
//     padding: 5px 30px 5px 10px;
   
//     &:focus{
//         box-shadow: none;
//         border-color: #dee2e6;
//     }
// }

.searchInput{
    font-size: 14px;
    color: $black;
    font-weight: 500;
    width: 200px;
    height: 40px;
    @include sm{
    width: 230px;

    }
    @include md{
    width: 250px;

    }
    &:focus{
        box-shadow: none;
        border-color: #dee2e6;
    }
    &::placeholder{
        font-size: 14px;
        color: $black;
        font-weight: 500;
    }
}

.managepatientsTable{
    margin-top: 20px;
    // border: 1px solid #C1C1C1;
    .mYSul{
    }
    // & .cxFtRn {
    //     background-color: lighten($theme2, 60);
    //     border-top: 1px solid lighten($theme2, 60) ;
        

    // }
    & .cfvlby{
        height: 57px;
        background-color: lighten($theme2, 60);
        border-bottom: 1px solid lighten($theme2, 60) ;
        display: flex;
        align-items: stretch;
        width: 100%;
        min-height: 52px;
        // margin-bottom: 10px;
        & .ofEne{
            font-size: 14px;
            font-weight: 600;
        }
    }
    & .WubHI {
        display: inline-flex;
        align-items: center;
        justify-content: inherit;
        height: 100%;
        width: 100%;
        outline: none;
        user-select: none;
        overflow: hidden;
        cursor: pointer;
    }
    & .tableButton{
        margin-left: auto;
        margin-right: 10px;
       & .editBtn{
            color: $white;
            background-color: $theme2;
            border-radius: 5px;
            padding: 8px 10px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all .3s ease-in-out;
            & .editPencilImg{
                height: 15px;
                width: 15px;
                display: flex;
            }
            &:hover{
                background-color: $theme2;
                opacity: 0.9;
            }
        }
    }
}