.formcontrolCustom {
  height: 48px;

  border: none;
  // font-weight: 600;
  border-radius: 100px;
  padding-left: 20px !important;
  &:focus {
    border-color: #dee2e6;
    border-bottom: 2px solid $theme2 !important;
  }
  @include xxl {
    height: 60px !important;
  }
}
//<<<<<<< HEAD
.sel-ag{
    max-width: 8.5rem;
}
.labelTxt{
    font-size: 15px;
    opacity: 0.5;
    background-color: transparent !important;
// =======
// .labelTxt {
//   font-size: 15px;
//   opacity: 0.5;
//   background-color: transparent !important;
// >>>>>>> master
}
.formControlLable {
  padding-left: 20px !important;
}
.signinBtn {
  color: $white;
  background-color: $theme2;
  font-size: 15px;
  font-weight: 500;
  border-radius: 100px;
  padding: 12px 25px;
  width: 100%;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: $theme2;
    opacity: 0.9;
  }
}
.inputCheckbox {
  accent-color: $theme2 !important;
  height: 16px;
  width: 16px;
  cursor: pointer;
}
.authpages {
  & .signupSection {
    background-image: url(../img/signBg.png);
    background-repeat: no-repeat;
    background-size: cover;
    overflow: auto;
    .registerImg {
      height: 55vh;
      position: static;

      width: 100%;
      & img {
        object-position: center;
      }
      @include lg {
        position: fixed;
        width: calc(50% - 30px);
        height: 100vh;
        top: 0%;
        left: 0%;
      }
    }
    & .inputsignSide {
      // padding-bottom: 100px;
      & .headingI {
        font-size: 30px;
        font-weight: 400;
        @include sm {
          font-size: 30px;
        }
        @include md {
          font-size: 35px;
        }
        @include lg {
          font-size: 40px;
        }
      }
      & .subHeding {
        font-size: 16px;
        font-weight: 400;
        line-height: 120%;
        opacity: 0.6;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      & .MainForm {
        padding-top: 35px;
        & .forgottxt {
          color: $theme2;
          font-weight: 600;
          font-size: 15px;
        }
        & .remmebertxt {
          cursor: pointer;
          color: $black;
          opacity: 0.7;
        }
        & .colclass {
          line-height: 60%;
        }
      }
    }
    & .formcontroltxtCustom {
      border: none;
      border-radius: 12px;
      height: 100px;
      &:focus {
        border-bottom: 2px solid $theme2 !important;
      }
    }
  }
}
.cancelColor {
  background-color: $theme3 !important;
  &:hover {
    background-color: lighten($theme3, 10) !important;
  }
}
.changePass {
  & .changeNav {
    background-color: $white;
    border-bottom: 4px solid $theme1 !important;
    box-sizing: border-box;
    // display: inline-flex;
    & .changeNavItem {
      & .changeNavLink {
        border: 0 !important;
        color: rgba(0, 0, 0, 0.4);
        font-size: 15px !important;
        font-weight: 600 !important;
        border-radius: 0px;
        padding: 10px 0px;
        width: 150px;
        border: 1px solid $theme1;
        border-bottom: 4px solid transparent !important;
        @include sm {
          width: 180px;
        }
        @include md {
          width: 190px;
        }
        &:hover {
        }
        &.active {
          position: relative;
          &::after {
            position: absolute;
            content: "";
            bottom: -7px;
            left: 0%;
            height: 4px;
            width: 100%;
            background-color: $theme2;
          }
          // border-bottom: 4px solid $theme2 !important;
          // color: $theme2 !important;
        }
      }
    }
  }
  & .changePassSec {
    & .changeContainer {
      // background-image: url(../img/signBg.png);
      // background-repeat: no-repeat;
      & .heading {
        font-size: 35px;
        color: $black;
      }
      & .keyDiv {
        border: 2px solid black;
        border-radius: 50%;
        height: 120px;
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        background-color: $theme2;
        margin-top: -80px;
        margin-inline: auto;
        & .keyImg {
          height: 34px;
          width: 84px;
        }
      }
      & .Forgetxt {
        & .forgrtAnc {
          color: $black;
          font-size: 14px;
        }
      }
    }
  }
  & .resetInput {
    padding: 20px;
  }
  & .editProfile {
    & .heading {
      font-size: 20px;
      font-weight: 500;
    }
    & .profile {
      & .profDiv {
        position: relative;
        display: inline-flex;
        & .profImg {
          overflow: hidden;
          border-radius: 50%;
          height: 100px;
          width: 100px;
          margin-inline: auto;
          display: flex;
          border: 1px solid #dee2e6;
          align-items: center;
          justify-content: center;
          background-color: $theme2;
          @include sm {
            height: 120px;
            width: 120px;
          }
          @include lg {
            height: 150px;
            width: 150px;
          }
        }
      }
    }
    & .formcontroltxtCustom {
      border-radius: 12px;
      height: 100px;
      &:focus {
        border-bottom: 2px solid $theme2 !important;
      }
    }
  }
}
.eyeImg {
  border-radius: 0 100px 100px 0;
  position: absolute;
  top: 50%;
  background-color: transparent !important;
  right: 6%;
  transform: translateY(-50%);
  border: 0;
  @include sm {
    right: 3%;
  }
  & .eyeImgDiv {
    height: 20px;
    width: 20px;
    display: flex;
    cursor: pointer;
  }
}
.profTxt {
  font-size: 35px;
  font-weight: 700;
  color: $white;
  // @include sm{
  //     font-size: 45px;
  // }
  @include sm {
    font-size: 50px;
  }
  @include lg {
    font-size: 70px;
  }
}
.errorMsg {
  font-size: 14px;
  margin-left: 10px;
}
.alertAdmin {
  & .successMsg {
    border-radius: 10px;
    border: 1px solid #a3cfbb !important;
    background-color: #d1e7dd;
    padding: 12px 10px;
    // display: inline-block;
    & .borderDiv {
      padding: 0;
    }
    & .successImg {
      height: 15px;
      width: 15px;
    }
    & .successtxt {
      text-align: left;
      & .head {
        font-size: 20px;
        font-weight: 500;
        line-height: 110%;
        color: $black;
        @include sm {
          font-size: 25px;
        }
      }
      & .subHead {
        font-size: 12px;
        line-height: 110%;
        color: $black;
        opacity: 0.8;
        @include sm {
          font-size: 14px;
        }
      }
    }
    & .closeBtn {
      // padding: 20px 10px;
      @include sm {
        // padding: 20px 30px;
      }
      & .closeBtnAnc {
        font-size: 14px;
        color: $black;
        opacity: 0.9;
        @include sm {
          font-size: 16px;
        }
      }
    }
  }
  & .dangerMsg {
    border: 1px solid #f1aeb5 !important;
    background-color: #f8d7da;
  }
}
.remmebertxt {
  cursor: pointer;
  color: $black;
  opacity: 0.7;
}
.adminBody {
  & .registerUser {
    & .heading {
      font-size: 20px;
    }
  }
}
.adminPAgeFound {
  height: 100vh;
  & .MainPage {
    &.pageFound {
      height: 100vh;
      background-color: #dee2e6;
      background-image: url(../img/404pageAgain.png);
      background-repeat: no-repeat;
      background-position: center;
    }
    & .number {
      font-size: 60px;
      color: $theme2;
      font-weight: 800;
      letter-spacing: 5px;
      line-height: 110%;
      opacity: 0.8;
      @include sm {
        font-size: 50px;
      }
      @include md {
        font-size: 70px;
      }
      @include lg {
        font-size: 100px;
      }
    }
    & .accessDenied {
      font-size: 30px;
      @include sm {
        font-size: 40px;
      }
      @include lg {
        font-size: 50px;
      }
    }
    & .accessImg {
      aspect-ratio: 1/1 !important;
    }
    & .heading {
      font-size: 24px;
      color: $theme2;
      font-weight: 600;
      line-height: 110%;
      opacity: 0.6;
      @include sm {
        font-size: 26px;
      }
      @include md {
        font-size: 28px;
      }
      @include lg {
        font-size: 30px;
      }
    }
    & .errorImg {
      aspect-ratio: 3/2;
      margin-left: -23px;
    }
    & .internalImg {
      aspect-ratio: 4/3;
    }
  }
}
.loaderPage {
  & .loaderImg {
    height: 100px;
    width: 100px;
  }
  & .preloader {
    width: 50px;
    height: 50px;
    position: relative;
  }
  & .spinner {
    width: 100%;
    height: 100%;
    border: 6px solid transparent;
    border-top: 6px solid $theme2;
    border-radius: 50%;
    animation: rotate 1s linear infinite;
  }
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.controlRounded {
  border-radius: 10px !important;
}
.audioGram {
  & .heading {
    font-size: 16px;
    font-weight: 500;
    color: $black;
    background-color: lighten($theme2, 60);
    padding: 10px;
    border-radius: 8px;
  }
  & .audiogramTable {
    margin-top: 10px;
    & thead {
      & tr {
        & th {
          padding: 8px 5px 0px 5px !important;
          font-size: 14px;
          border: 1px solid #dee2e6;
          // border-bottom: 0;
        }
      }
    }
    & tbody {
      & tr {
        & td {
          // padding: 8px 2px !important;
          padding: 0;
          border: 1px solid #dee2e6;
          // border-top: 0;
        }
      }
    }
  }
  & .tableFormControl {
    height: 25px !important;
    width: 40px !important;
    padding: 5px 8px !important;
    // border-radius: 5px;
    border: 1px solid transparent;
    border: 0 !important;
    &:focus {
      // border-color: #dee2e6;
      border: 1px solid $theme2 !important;
      border-radius: 0;
    }
  }
  & .sideTxt {
    & .sliderRange {
      & .formRange {
        height: 40px !important;
      }
    }
    & .transtxtdiv {
      background-color: lighten($theme2, 60);
      padding: 10px;
      border-radius: 8px;
      margin-top: 15px;
      & .transtxt {
        font-weight: 500 !important;
        color: $black !important;
        opacity: 1 !important;
        font-size: 14px !important;
        line-height: 100%;
      }
    }
    & .inputDiv {
      padding-top: 10px;
      @include sm {
        padding-top: 14px;
      }
      @include lg {
        padding-top: 20px;
      }
      & .transInput {
        &:focus {
          border-color: #dee2e6;
        }
      }
    }
    & .songsSection {
      // padding: 10px;
      // @include sm{
      //     padding: 14px;
      // }
      // @include lg{
      //     padding: 20px;
      // }
      & .songs {
        border: 1px solid #dee2e6;
        padding: 5px 10px;
        background-color: #dee2e6;
        border-radius: 5px;
        & .moveImg {
          height: 13px;
          width: 13px;
          display: flex;
        }
      }
    }
    & .headphoneSection {
      // padding: 10px;
      // @include sm{
      //     padding: 14px;
      // }
      // @include lg{
      //     padding: 20px;
      // }
    }
    & .logStartSection {
      // padding: 10px;
      // @include sm{
      //     padding: 14px;
      // }
      // @include lg{
      //     padding: 20px;
      // }
    }
  }
  .dbtxt {
    font-size: 14px;
    font-weight: 700;
    // margin-bottom: 2rem;
    margin-top: 5px;
  }
  .range-slider {
    width: 100%;
    margin: 0 auto;
    position: relative;
    // margin-top: 2.5rem;
    // margin-bottom: 2rem;
  }
  .range {
    -webkit-appearance: none;
    width: 100%;
  }
  .range:focus {
    outline: none;
  }
  .range::before {
    left: 0;
    content: attr(data-min);
  }
  .range::after {
    right: 0;
    content: attr(data-max);
  }
  .range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.8rem;
    cursor: pointer;
    animate: 0.2s;
    // background: linear-gradient(90deg, $theme2 var(--range-progress), #dee4ec var(--range-progress));
    border-radius: 1rem;
  }
  .range::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 0.25rem solid $theme2;
    box-shadow: 0 1px 3px rgba(0, 0, 255, 0.3);
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
    height: 28px;
    width: 28px;
    transform: translateY(calc(-50% + 8px));
  }
  .tooltip {
    position: absolute;
    top: -2rem;
  }
  .tooltip span {
    position: absolute;
    text-align: center;
    display: block;
    line-height: 1;
    padding: 0.125rem 0.25rem;
    color: #fff;
    border-radius: 0.125rem;
    background: $theme2;
    font-size: 1.25rem;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .tooltip span:before {
    position: absolute;
    content: "";
    left: 50%;
    bottom: -8px;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border: 4px solid transparent;
    border-top-color: $theme2;
  }
  .audioGramAccordian {
    & .accordianHeader {
      & .accordion-button {
        font-size: 16px;
        font-weight: 600;
        &:focus {
          box-shadow: none !important;
        }
        // &:not(.collapsed){
        //     background-color: #EEF1F9 !important;
        // }
      }
    }
  }
}
.customBadge {
  font-size: 12px;
  padding: 0.3em 0.8em;
  border-radius: 50px;
  display: inline-block;
}
.prcolor {
  color: #0d6efd;
  background-color: lighten($color: #0d6efd, $amount: 40);
}
.sucolor {
  color: #34c38f;
  background-color: rgba(52, 195, 143, 0.18);
}
.incolor {
  color: #0dcaf0;
  background-color: lighten($color: #0dcaf0, $amount: 45);
}
.warcolor {
  color: #f1b44c;
  background-color: rgba(241, 180, 76, 0.18);
}
.dancolor {
  color: #dc3545;
  background-color: lighten($color: #dc3545, $amount: 40);
}
input:-webkit-autofill {
  background-color: $white !important;
  color: $black !important;
  box-shadow: 0 0 0 50px $white inset !important;
}
.ManageClient {
  & .clientHeader {
    border-bottom: 2px solid #d1e7dd;
    padding-bottom: 10px;
    & .Head {
      font-size: 16px;
      font-weight: 500;
      color: $black;
      padding-bottom: 10px;
      @include sm {
        font-size: 18px;
      }
      @include md {
        font-size: 20px;
      }
    }
    & .clintSide {
      padding-top: 10px;
      border-top: 1px solid #d1e7dd;
      & .clientNames {
        font-size: 16px;
        font-weight: 600;
        color: $black;
        line-height: 110%;
        padding: 5px 0;
        @include sm {
          font-size: 18px;
        }
      }
      & .contactClient {
        font-size: 15px;
        line-height: 110%;
        color: $black;
        font-weight: 500;
        padding: 5px 0;
      }
      & .clientEmail {
        font-size: 15px;
        line-height: 110%;
        color: $black;
        font-weight: 500;
      }
      & .assignbtndiv {
        & .assignBtn {
          border: 1px solid #d1e7dd;
          border-radius: 7px;
          display: inline-block;
          font-size: 14px;
          font-weight: 500;
          padding: 10px 18px;
          color: $black;
          opacity: 0.7;
        }
      }
    }
  }
  & .mainContent {
    padding-block: 15px;
    & .clientContent {
      padding-block-end: 15px;
      padding-block-start: 0px;
      padding-inline: 10px;
      border-bottom: 2px solid #dddddd;
      // background-color: #dee2e670;
      margin-block: 15px;
      // border-radius: 8px;
      & .manageClientAccordain {
        & .accordion-item {
          border-radius: 0 !important;
        }
        & .accordianHeader {
          & .accordion-button {
            font-size: 16px;
            font-weight: 600;
            @include sm {
              font-size: 17px;
            }
            @include md {
              font-size: 18px;
            }
            &:focus {
              box-shadow: none !important;
            }
            &:not(.collapsed) {
              background-color: #eef1f9 !important;
            }
          }
        }
        & .manageClienSubtAccordain {
          & .accordianSubheader {
            & .accordion-button {
              font-size: 14px;
              font-weight: 600;
              color: $black;
              @include sm {
                font-size: 16px;
              }
              &:focus {
                box-shadow: none !important;
              }
              &:not(.collapsed) {
                background-color: #eef1f9 !important;
              }
            }
          }
        }
      }
      & .paraContent {
        border: 1px solid #d1e7dd;
        // padding: 10px 20px;
        & .paraOne {
          font-size: 16px;
          font-weight: 600;
          background-color: #eef1f9;
          padding: 10px 20px;
        }
        & .paraTwo {
          padding: 10px 20px;
        }
      }
      .currentSpan {
        font-size: 16px;
        font-weight: 500;
      }
      .paraResult {
        font-size: 14px;
        font-weight: 600;
        @include sm {
          font-size: 16px;
        }
      }
      & .setUptxt {
        font-size: 14px;
        color: $black;
        padding: 10px;
      }
      & .timelinebtnDiv {
        & .timelinetxt {
          font-size: 14px;
          font-weight: 600;
          @include md {
            font-size: 16px;
          }
        }
        & .timelineBtn {
          border: 1px solid #a3cfbb;
          border-radius: 6px;
          transition: all 0.3s ease-in-out;
          & .timeBtnanc {
            color: $black;
            font-size: 14px;
            line-height: 100%;
            opacity: 0.8;
            padding: 4px 20px;
          }
          &:hover {
            background-color: #dee4ec;
          }
        }
      }
      & .heading {
        font-size: 20px;
        font-weight: 700;
      }
      & .paraOne {
        font-size: 15px;
        font-weight: 600;
      }
      & .formData {
        font-size: 14px;
      }
      & .audiogramDataTable {
        margin-bottom: 0;
        // border-collapse: separate;
        // border-spacing: 5px 0;
        & thead {
          & tr {
            & th {
              font-size: 13px !important;
              padding: 5px !important;
              border-bottom: 0 !important;
              // border-radius: 10px !important;
              // border-bottom-right-radius: 0% !important;
              // border-bottom-left-radius: 0% !important;
              text-align: center;
            }
          }
        }
        & tbody {
          & tr {
            & td {
              font-size: 13px !important;
              border-top: 0 !important;
              padding: 5px;
              // border-radius: 10px !important;
              // border-top-right-radius: 0px !important;
              // border-top-left-radius: 0px !important;
              text-align: center;
            }
          }
        }
      }
      & .lefttxt {
        font-size: 13px;
        font-weight: 600;
        color: #414141;
        text-align: center;
        margin-top: 10px;
      }
    }
  }
}
.range-slider {
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.range {
  -webkit-appearance: none;
  width: 100%;
}
.range:focus {
  outline: none;
}
.range::before {
  left: 0;
  content: attr(data-min);
}
.range::after {
  right: 0;
  content: attr(data-max);
}
.range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.8rem;
  cursor: pointer;
  animate: 0.2s;
  background: linear-gradient(
    90deg,
    $theme2 var(--range-progress),
    #dee4ec var(--range-progress)
  );
  border-radius: 1rem;
}
.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 0.25rem solid $theme2;
  box-shadow: 0 1px 3px rgba(0, 0, 255, 0.3);
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  height: 28px;
  width: 28px;
  transform: translateY(calc(-50% + 8px));
}
.tooltip {
  position: absolute;
  top: -2rem;
}
.tooltip span {
  // position: absolute;
  text-align: center;
  display: block;
  line-height: 1;
  padding: 5px 10px;
  color: #fff;
  border-radius: 0.125rem;
  background: $theme2;
  font-size: 1.25rem;
  left: 50%;
  transform: translate(-50%, 0);
}
.tooltip span:before {
  position: absolute;
  content: "";
  left: 50%;
  bottom: -8px;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-top-color: $theme2;
}
.dbtxt {
  font-size: 12px;
  font-weight: 700;
  margin-top: 5px;
  @include sm {
    font-size: 14px;
  }
}
.accessSection {
  background-image: url(../img/access.png);
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  & .txt {
    font-size: 56px;
    font-weight: 800;
    color: $theme2;
    position: absolute;
    right: 36%;
    bottom: 15%;
  }
}
.couponsSection {
  & .mainCard {
    position: relative;
    & .borderDiv {
      border-right: 2px dotted black;
      height: 100%;
      width: 3px;
      background-color: transparent;
      position: relative;
      // padding-block: 20px;
      &::after {
        content: "";
        display: block;
        background: #f6f6f6;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 20px;
        height: 20px;
        bottom: 0%;
        border-radius: 20px;
        z-index: 10;
      }
      &::before {
        content: "";
        display: block;
        background: #f6f6f6;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 20px;
        height: 20px;
        top: 0%;
        border-radius: 20px;
        z-index: 10;
      }
    }
    & .cardIcon {
      height: 60px;
      width: 60px;
      position: relative;
      margin: auto;
    }
    & .cardContent {
      padding-block: 20px;
      & .head {
        font-size: 13px;
        font-weight: 600;
        @include sm {
          font-size: 15px;
        }
      }
      & .subHead {
        font-size: 12px;
        display: flex;
        gap: 10px;
        @include sm {
          font-size: 14px;
        }
      }
      & .editImg {
        height: 10px;
        width: 10px;
        display: flex;
      }
    }
    & .cuopanDropdown {
      & button {
        box-shadow: none !important;
        background: transparent;
        color: $black;
        border: 0;
        margin: 0;
        padding: 0;
        font-weight: 600;
        font-size: 13px;
        &::after {
          border: 0;
        }
        @include sm {
          font-size: 16px;
        }
      }
      & .DropdownMenu {
        width: 100%;
        padding: 10px;
        & .dropdownItem {
          text-wrap: wrap;
          &:active {
            background-color: $theme2;
          }
        }
      }
    }
    & .editDiv {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-color: $theme2;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0%;
      right: 0%;
      & .editImgT {
        height: 20px;
        width: 20px;
        display: flex;
      }
    }
  }
}

table.leftAudioGram {
  & tbody {
    & tr {
    }
    & td {
      color: #2500ff;
    }
  }
}

table.leftModifiedAudiogram{
    & tbody {
        & tr {

        }
        & td {
            max-width: 3em;
            min-width: 3em;
        }
        & input {
            max-width: 3em;
            min-width: 3em;
            color: #2500ff;
        }
    }
}

table.rightAudioGram{
    & tbody {
        & tr {

        }
        & td {
            color: #984915;
        }
  }
}

table.rightModifiedAudiogram{
    & tbody {
        & tr {

        }
        & td {
            max-width: 3em;
            min-width: 3em;
        }
        & input {
            max-width: 3em;
            min-width: 3em;
            color: #984915;
        }
    }
}

div[id^="neural-stimulus"][id$="-range-tooltip"] {
  opacity: 1;
  & span {
    font-size: 12px;
    top: 7px;
    background: #000;
    padding: 5px;
    text-wrap: nowrap;
  }
}

.dbColored {
  color: #2500ff;
}
.homePageBtn {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: #34617e;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  & .honeBtn {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    color: white;
  }
  & .homepageBtnAnc {
    height: 30px;
    width: 30px;
    display: inline-block;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
  }
  & .btnName {
    color: whhite;
    font-size: 14px;
    text-decoration: none;
  }
}
.homeBtnheaderDiv {
  background-color: #000;
  color: #fff;
  font-size: 15px;
  line-height: 120%;

  border-radius: 6px;
  & .homeBtnheaderDivanc {
    color: #fff;
    text-decoration: none;
    padding: 8px 12px;
    display: inline-block;
  }
}

.toptipSection {
  position: relative;
  & span {
    // position: absolute;
    font-size: 12px;
    background-color: black;
    color: white;
    opacity: 1;
    visibility: visible;
    padding: 5px;
    // border-radius: 2px;
    // margin-bottom: 1px;
    text-align: left;
  }
}

div[id^="neural-stimulus2-range-tooltip-"] {
  opacity: 1;
  & span {
    font-size: 12px;
    top: 7px;
    background: #000;
    padding: 5px;
    text-wrap: nowrap;
  }
}

.usage-event .tooltip {
  position: absolute;
  top: -2.9rem;
}
