.App {
  text-align: center;
  background-color: green;
}

.label {
  display: block;
  font-size: larger;
  padding: 5px;
}

.input {
  font-size: larger;
  padding: 5px;
  margin: 2px;
}

.btn {
  color: white;
  background-color: red;
  border-radius: 5px;
  font-size: larger;
  display: block;
  padding: 5px;
  margin: 10px 0px;
}

.messages {
  display: flex;
  justify-content: center;
}

.error {
  display: block;
  background-color: red;
  color: white;
  width: fit-content;
  height: 50px;
  padding: 5px;
}

button {
  display: inline-block;
  margin-right: auto;
  margin-left: auto;
  padding: 7px 12px;
  border: none;
  border-radius: 4px;
}

.templateTable .jJhCqB div:first-child {
  white-space: normal !important;
  padding: 10px 0;
}

.dropdown-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.formSectionTitle {
  color: #486284;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e0e0e0;
}
