@mixin xs {
    @media only screen and (min-width: 300px) {
        @content;
    }
}

@mixin sm {
    @media only screen and (min-width: 576px) {
        @content;
    }
}

@mixin md {
    @media only screen and (min-width: 768px) {
        @content;
    }
}

@mixin lg {
    @media only screen and (min-width: 992px) {
        @content;
    }
}

@mixin xl {
    @media only screen and (min-width: 1200px) {
        @content;
    }
}

@mixin xxl {
    @media only screen and (min-width: 1400px) {
        @content;
    }
}

@mixin xxxl {
    @media only screen and (min-width: 1600px) {
        @content;
    }
}

@mixin ellipsis($line: 2) {
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin sectionPadding($pt: 30px, $pb: 30px, $section-padding: 1px) {
    padding-top: calc($pt * $section-padding);
    padding-bottom: calc($pb * $section-padding);
}