
.adminSideBar{
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    height: 100vh;
    transition: 0.3s ease-in-out;
    width: 248px;
    background-color: $white;
    // height: 100vh;
    transform: translateX(-100%);
    padding: 0 16px;
    --tw-shadow: 0px 0px 1px rgba(40, 41, 61, .08), 0px .5px 2px rgba(96, 97, 112, .16);
    --tw-shadow-colored: 0px 0px 1px var(--tw-shadow-color), 0px .5px 2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
    @include lg {
        position: static;
        transform: translateX(0);
    }
    & .sideBarHead{
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        & .dashboardLogo{
            & img{
                height: 40px;
            }
        }
        & span{
            // font-size: 1.25rem;
            font-size: 14px;
            line-height: 1.75rem;
            font-weight: 600;
            color: $black;
        }
    }
    & .sidebarBody{
        & .siderbarHeadingUl{
            list-style: none;
            & .sidebarHeadingLi{
                color: $light-black;
                margin-bottom: 1rem;
                margin-top: 1rem;
                font-size: .75rem;
                line-height: 1rem;
                font-weight: 600;
                text-transform: uppercase;
            }
        }
        & .adminAccordion{
            & .accordion-item{
                border: 0;
                & .accordion-header{
                    display: flex;
                    align-items: center;
                    & .accordion-button {
                        font-size: 14px;
                        font-weight: 500;
                        color: $light-black;
                        position: relative;
                        &:not(.collapsed){
                            background-color: $theme1;
                            &::after{
                                transform: rotate(90deg);
                            }
                            &::before{
                                background-color: #D4DBE3;
                            }
                        }
                        &::after{
                            content: '';
                            background-image: url(../../admin/img/rytArrow.svg);
                            background-repeat: no-repeat;
                            background-size: contain;
                            width: 11px;
                            height: 11px;
                            transform: rotate(0deg);
                            position: absolute;
                            right: 13px;
                        }
                        &::before{
                            content: '';
                            width: 22px;
                            height: 22px;
                            background-color: #F1F5F9;
                            border-radius: 50%;
                            position: absolute;
                            right: 8px;
                        }
                        &:focus{
                            box-shadow: unset;
                        }
                        &:not(.collapsed) {
                            background-color: $theme1;
                            border-radius: 0.375rem;
                        }
                        padding: 0.72rem 10px 0.72rem 10px;
                    }
                }
                & .accordion-collapse {
                    & .accordion-body{
                        padding: 12px .25rem 12px .25rem;
                        font-size: 14px;
                        color: $light-black;
                        & .accordionUl{
                            padding-left: 38px;
                            & .accordionLi{
                                list-style: none;
                                margin-bottom: 12px;
                                &:last-child{
                                    margin-bottom: 0;
                                }
                                &:hover{
                                    & .accordionAnchor{
                                        color: #070808;
                                    }
                                }
                                & .accordionAnchor{
                                    text-decoration: none;
                                    font-size: 13px;
                                    font-weight: 500;
                                    color: $light-black;
                                    width: 100%;
                                    display: block;
                                    transition: 0.3s linear;
                                }
                            }
                        }
                    }
                }
            }
        }
        & .sidebarLi{
            transition: 0.3s ease-in-out;
            margin-bottom: 2px;
            &.active{
                background-color: #EEF1F9;
                border-radius: 0.375rem;
            }
            &:hover{
                background-color: #EEF1F9;
                border-radius: 0.375rem;
                & .arrowIcon{
                    transform: translateX(5px);
                }
            }
            & .sidebarLiAnchor{
                padding: 0.72rem 10px 0.72rem 10px;
                text-decoration: none;
                font-size: 14px;
                font-weight: 500;
                color: $light-black;
                & .homeIcon{
                    margin-top: -5px;
                    height: 20px;
                }
                & .arrowIcon{
                    transition: 0.3s linear;
                }
            }
        }
    }
    & .crossIcon{
        height: 25px;
        width: 25px;
        padding: 5px;
        background-color: $black;
        display: flex;
        align-items: center;
        justify-content: center;
        // position: absolute;
        top: 2%;
        right: 5%;
        @include lg{
            display: none;
        }
    }
    .accordianSideIcon{
        // max-height: 20px;
        height: 20px;
        min-height: 20px;
    }
    .accordianSideButton{
        & .accordion-button{
            gap: 8px;
        }
    }
}