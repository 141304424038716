
.commonCard{
    // border-style: solid;
    border-color: #e5e7eb;
    padding: 10px;
    padding-block: 24px;
    box-shadow:  0px 0px 1px rgba(40, 41, 61, .08), 0px .5px 2px rgba(96, 97, 112, .16);
    background-color: $white;
    border-radius: 5px;
    @include sm{
        padding: 15px;
    }
    @include md{
        padding: 20px;
    }
    @include lg{
        padding: 24px;
    }
    & .card-header{
        border-bottom: 1px solid $theme1;
        padding:0px 0px 13px 0px;
        & .SectionHeadingMain{
            font-size: 18px;
            color: $black;
            font-weight: 500;
            @include sm{
                font-size: 20px;
            }
        }
       
        & .dropIcon{
            width: 20px;
            height: 20px;
        }
    }
    & .card-body{
        padding: 13px 0px 0px 0px;
    }
}

.payment-option{
  width: 100%;
  font-size: 1rem;
}

label {
  width: 100%;
  font-size: 1rem;
}

.card-input-element+.card {
  -webkit-box-shadow: none;
  border: 1px solid #000 !important;
  height: calc(16px + 2rem);
  color: #000;
  box-shadow: none;
  border-radius: 4px;
  font-size: 14px;
}

.card-input-element+.card:hover {
  cursor: pointer;
}

.card-input-element:checked+.card {
  background-color: #2a2a2a !important;
  color: #fff;
  -webkit-transition: border .3s;
  -o-transition: border .3s;
  transition: border .3s;
}

// .card-input-element:checked+.card::after {
//   content: '\e5ca';
//   color: #AFB8EA;
//   font-family: 'Material Icons';
//   font-size: 24px;
//   -webkit-animation-name: fadeInCheckbox;
//   animation-name: fadeInCheckbox;
//   -webkit-animation-duration: .5s;
//   animation-duration: .5s;
//   -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
//   animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
// }

@-webkit-keyframes fadeInCheckbox {
  from {
    opacity: 0;
    -webkit-transform: rotateZ(-20deg);
  }
  to {
    opacity: 1;
    -webkit-transform: rotateZ(0deg);
  }
}

@keyframes fadeInCheckbox {
  from {
    opacity: 0;
    transform: rotateZ(-20deg);
  }
  to {
    opacity: 1;
    transform: rotateZ(0deg);
  }
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
}

.addPatientBtn:disabled{
    opacity: 70%;
    cursor: not-allowed;
}