@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .partner-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 2rem;
    align-items: center;
  }

  .hover-lift {
    @apply transition-transform duration-200 hover:scale-105;
  }

  .blur-backdrop {
    backdrop-filter: blur(8px);
  }
}

.volume-text {
  margin-left: 10px;
  font-size: 0.9em;
  color: #666;
}

.volume-label {
  position: absolute;
  bottom: -20px;
  left: 35%;
  font-size: 0.9em;
  color: #666;
}

.customHGraph {
  position: relative;
  padding-bottom: 30px;
}

.table-filters {
  padding: 0.5rem 1rem;
  background: #fff;
}

.filter-input {
  height: 32px;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.filter-input:focus {
  border-color: #86b7fe;
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
}

.filter-reset-btn {
  height: 32px;
  padding: 0.25rem 1rem;
  border: 1px solid #dee2e6;
  background-color: transparent;
  color: #6c757d;
  font-size: 0.875rem;
}

.filter-reset-btn:hover {
  background-color: #f8f9fa;
  border-color: #dee2e6;
  color: #495057;
}

.filter-input.form-select {
  padding-right: 1.75rem;
  background-position: right 0.5rem center;
}

.row.g-2.align-items-center > div {
  margin-bottom: 0;
}
