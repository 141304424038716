@import "./mixins.scss";
@import "./fonts.scss";
@import "./frontend.scss";

body {
  font-family: "SF Pro Display" !important;
  //   height: 100vh;
  //   overflow: hidden;
  //   & #root {
  //     height: 100%;
  //     overflow: hidden;
  //   }
}

.graphtooltip {
  position: absolute;
  background-color: rgb(0 0 0 / 70%);
  color: white;
  padding: 6px;
  font-size: 12px;
  border-radius: 3px;
  pointer-events: none;
  width: 100px;
}

.customHGraph {
  flex-grow: 1;
  position: relative;
  width: calc(100% - 200px);
  height: 40px;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #aaa;
  margin: 0px 25px 0px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.event-row {
  display: flex;
  width: 100%;
  align-items: center;
  /* height: 40px; */
  margin: 10px 0px 10px 0px;
}
.event-date {
  width: 100px;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}
.event-container {
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 100%;
  /* border: 1px solid red; */
}
.event-stats {
  width: 100px;
  font-size: 10px;
  display: flex;
  align-items: center;
}
.non-usage-event {
  border: 0px;
  font-size: 14px;
  height: 20px;
}
.usage-event {
  position: absolute;
  bottom: 0; /* Align to the bottom of the container */
  background-color: rgba(0, 120, 255, 0.7); /* Semi-transparent blue */
}
.audiogram-event {
  height: 50px;
}
.timeline-label {
  position: absolute;
  top: calc(100% - 4px);
  font-size: 8px;
  color: #666;
}
.left {
  left: -20px;
}
.right {
  right: -20px;
}
.center {
  width: 100%;
  text-align: center;
}
.word {
  display: inline-block;
  white-space: nowrap;
  background-color: #fff;
  padding: 0px 5px;
}
.audiogram .values {
  font-size: 12px;
}
.audiogram .frequencies {
  font-size: 8px;
}
.audiogram td {
  text-align: center;
}
.audiogram .left {
  color: #0000ff;
}
.audiogram .right {
  color: #ff0000;
}
.audiogram .spacer {
  width: 60px;
}
.labeltext {
  font-size: 10px;
}
