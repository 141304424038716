.adminUser{
    border-radius: 50%;
    & img{
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 50%;
    }
}
.adminName{
    font-size: 14px;
    font-weight: 600;
    color: $light-black;
}
.adminSidebarDropdown{
    & .adminUser{
        height: 35px;
        width: 35px;
        border-radius: 100%;
        background-color: $theme2;
        display: flex;
        align-items: center;
        justify-content: center;
        @include sm{
            width: 40px;
            height: 40px;
        }
        & .adminUserImg{
            color: $white;
            font-size: 16px;
            font-weight: 700;
            text-transform: uppercase;
            @include sm{
                font-size: 18px;
            }
            
        }
    }
    & .dropdown-toggle{
        display: flex;
        align-items: center;
        padding: 0;
        &::after{
            content: '';
            background-image: url(../../admin/img/rytArrow.svg);
            background-repeat: no-repeat;
            background-size: contain;
            width: 17px;
            height: 17px;
            transform: rotate(90deg);
            display: none;
        }
        &:active{
            background-color: unset;
        }
        background-color: unset;
        border: 0;
    }
    & .dropdown-menu{
        position: absolute;
        right: 0 !important;
        width: 80px;
        left: unset !important;
        top: 14px !important;
        border: 0;
        padding: 10px;
        border-radius: 0.376rem;
        & .dropdown-item{
            font-size: 13px;
            font-weight: 500;
            color: $light-black;
            &:hover{
                background-color: $theme1;
            }
        }
    }
}
.menuBtn{
    & img{
        margin-top: 4px;
        cursor: pointer;
    }
}
.headerLogo{
    height: 35px;
    display: flex;
    align-items: center;
    @include lg{
        display: none;
    }
    & .logoTxt{
        font-size: 14px;
        line-height: 1.75rem;
        font-weight: 600;
        color: $black;
    }
}