@import "./variables";
@import "./mixins";
@import "./auth";
@import './adminSidebar';
@import './adminHeader';
@import './common';
@import './shiv';





// dashboard page sscc start
::-webkit-scrollbar {
    width: 2px;
}
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #C1C1C1;
}
::-webkit-scrollbar-track {
}

body{
    &.show{
        & .dashboardWrapper{
            & .adminSideBar{
                transform: translateX(0);
                @include lg {
                    width: 80px;
                }
            }
            & .adminDashBoard{
                @include lg {
                    width: calc(100% - 80px);
                }
            }
        }
        & .adminSideBar{
            & .sideBarHead{
                & span{
                    @include lg {
                        display: none;
                    }
                }
            }
            & .adminAccordion{
                & .accordion-header{
                    & .accordion-button {
                        &::after{
                            @include lg {
                                display: none;
                            }
                        }
                        &::before{
                            @include lg {
                                display: none;
                            }
                        }
                    }
                    & span{
                        @include lg {
                            display: none;
                        }
                    }
                }
                & .accordion-body{
                    @include lg {
                        display: none;
                    }
                }
            }
            & .sidebarLi{
                & .sidebarHeadingLiText{
                    @include lg {
                        display: none;
                    }
                }
            }
        }
    }
}

.dashboardWrapper{
    display: flex;
    align-items: center;
    overflow: hidden;
    background-color: $theme1;
    & .adminDashBoard{
        width: 100%;
        transition: 0.3s ease-in-out;
        height: 100vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        @include lg {
            width: calc(100% - 248px);
        }
    }
    & .adminHeader{
        height: 65px;
        background-color: $white;
        width: 100%;
        --tw-shadow: 0px 0px 1px rgba(40, 41, 61, .08), 0px .5px 2px rgba(96, 97, 112, .16);
        --tw-shadow-colored: 0px 0px 1px var(--tw-shadow-color), 0px .5px 2px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow);
        padding-inline: 1rem;
        @include lg {
            padding-inline: 1.5rem;
            height: 80px;
        }
    }
    & .adminBody{
        width:100%;
        overflow-y: auto;
        height: 100%;
        padding: 1rem;
        flex: 1 1 auto;
        @include lg {
            padding: 1.5rem;
        }
    }
}


// dashboard page sscc end